import React, { useState, useEffect } from 'react';
import DesignCanvas from './DesignCanvas';
import DesignTools from './DesignTools';
import OrderDetails from './OrderDetails';
import './ShirtDesigner.css';

const ShirtDesigner = () => {
  const [currentPage, setCurrentPage] = useState('design'); // 'design' or 'order'
  const [fabricCanvas, setFabricCanvas] = useState(null);
  const [designState, setDesignState] = useState({
    selectedColor: null,
    quantities: {
      XS: 0,
      S: 0,
      M: 0,
      L: 0,
      XL: 0,
      '2XL': 0,
      '3XL': 0
    },
    textElements: [],
    uploadedArtwork: [],
    deliveryMethod: 'pickup',
    deliveryDate: null,
    isRushOrder: false
  });

  const handleCanvasReady = (canvas) => {
    setFabricCanvas(canvas);
  };

  useEffect(() => {
    const restoreState = async () => {
      const savedState = sessionStorage.getItem('shirtDesignerState');
      if (savedState && fabricCanvas) {
        try {
          const state = JSON.parse(savedState);
          
          // Restore canvas state if it exists
          if (state.artwork?.canvasState) {
            fabricCanvas.loadFromJSON(state.artwork.canvasState, () => {
              // Make sure print area and shirt mockup are at the back
              const mockup = fabricCanvas.getObjects().find(obj => !obj.name);
              const printArea = fabricCanvas.getObjects().find(obj => obj.name === 'printArea');
              if (mockup) fabricCanvas.sendToBack(mockup);
              if (printArea) fabricCanvas.bringToFront(printArea);
              
              fabricCanvas.renderAll();
            });
          }

          // Restore other state
          setDesignState(state);
        } catch (error) {
          console.error('Error restoring canvas state:', error);
        }
      }
    };

    restoreState();
  }, [fabricCanvas]);

  return (
    <div className="shirt-designer">
      <div className="designer-layout">
        <div className="canvas-section">
          <DesignCanvas 
            designState={designState}
            setDesignState={setDesignState}
            onCanvasReady={handleCanvasReady}
          />
        </div>
        <div className="sidebar">
          {currentPage === 'design' ? (
            <DesignTools 
              designState={designState}
              setDesignState={setDesignState}
              onNext={() => setCurrentPage('order')}
            />
          ) : (
            <OrderDetails 
              designState={designState}
              setDesignState={setDesignState}
              onBack={() => setCurrentPage('design')}
              fabricCanvas={fabricCanvas}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShirtDesigner; 
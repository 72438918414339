import React, { useState, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import ShirtColorSelector from './ShirtColorSelector';
import './DesignTools.css';

const DesignTools = ({ designState, setDesignState, onNext }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const fileInputRef = useRef(null);

  const handleColorSelect = (color) => {
    setDesignState(prev => ({
      ...prev,
      selectedColor: color
    }));
  };

  // Add available fonts
  const fonts = [
    { name: 'Arial', value: 'Arial' },
    { name: 'Times New Roman', value: 'Times New Roman' },
    { name: 'Courier New', value: 'Courier New' },
    { name: 'Georgia', value: 'Georgia' },
    { name: 'Verdana', value: 'Verdana' }
  ];

  // Add common colors
  const colors = [
    { name: 'Black', value: '#000000' },
    { name: 'White', value: '#ffffff' },
    { name: 'Red', value: '#ff0000' },
    { name: 'Blue', value: '#0000ff' },
    { name: 'Green', value: '#008000' },
    { name: 'Sage', value: '#8BA888' }
  ];

  const handleAddText = () => {
    setDesignState(prev => ({
      ...prev,
      textElements: [...(prev.textElements || []), {
        text: 'Your Text Here',
        fontSize: 24,
        fill: '#000000',
        fontFamily: 'Arial',
        isSelected: true,
        bold: false,
        italic: false,
        underline: false
      }]
    }));
  };

  // Add handlers for font and color changes
  const handleFontChange = (fontFamily) => {
    setDesignState(prev => ({
      ...prev,
      selectedFont: fontFamily,
      textElements: prev.textElements?.map(el => 
        el.isSelected ? { ...el, fontFamily } : el
      )
    }));
  };

  const handleColorChange = (color) => {
    setDesignState(prev => ({
      ...prev,
      textElements: prev.textElements?.map(el => 
        el.isSelected ? { ...el, fill: color } : el
      )
    }));
  };

  const handleStyleChange = (style) => {
    setDesignState(prev => ({
      ...prev,
      textElements: prev.textElements?.map(el => 
        el.isSelected ? { 
          ...el, 
          [style]: !el[style] // Toggle the style
        } : el
      )
    }));
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file type
      if (!file.type.startsWith('image/')) {
        alert('Please upload an image file (PNG, JPG, GIF)');
        return;
      }

      // Check file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        alert('File size should be less than 5MB');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setDesignState(prev => ({
          ...prev,
          uploadedArtwork: Array.isArray(prev.uploadedArtwork) 
            ? [...prev.uploadedArtwork, {
                url: e.target.result,
                name: file.name,
                type: file.type
              }]
            : [{
                url: e.target.result,
                name: file.name,
                type: file.type
              }]
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="design-tools">
      <h2>Design Your Shirt</h2>
      
      {/* Color Selection */}
      <section className="tool-section">
        <h3>Select Color</h3>
        <ShirtColorSelector 
          selectedColor={designState.selectedColor}
          onColorSelect={handleColorSelect}
        />
      </section>

      {/* Enhanced Text Tool */}
      <section className="tool-section">
        <h3>Text Tools</h3>
        <div className="text-controls">
          <button 
            className="tool-button"
            onClick={handleAddText}
          >
            Add Text
          </button>
          
          {/* Font Selection */}
          <select 
            className="font-select"
            value={designState.selectedFont || 'Arial'}
            onChange={(e) => handleFontChange(e.target.value)}
          >
            {fonts.map(font => (
              <option key={font.value} value={font.value}>
                {font.name}
              </option>
            ))}
          </select>

          {/* Text Style Controls */}
          <div className="text-style-controls">
            <button
              className={`style-button ${designState.textElements?.find(el => el.isSelected)?.bold ? 'active' : ''}`}
              onClick={() => handleStyleChange('bold')}
              title="Bold"
            >
              B
            </button>
            <button
              className={`style-button ${designState.textElements?.find(el => el.isSelected)?.italic ? 'active' : ''}`}
              onClick={() => handleStyleChange('italic')}
              title="Italic"
            >
              I
            </button>
            <button
              className={`style-button ${designState.textElements?.find(el => el.isSelected)?.underline ? 'active' : ''}`}
              onClick={() => handleStyleChange('underline')}
              title="Underline"
            >
              U
            </button>
          </div>

          {/* Color Picker */}
          <div className="color-picker-container">
            <label className="color-label">Text Color</label>
            <button 
              className="color-preview"
              onClick={() => setShowColorPicker(!showColorPicker)}
              style={{ 
                backgroundColor: designState.textElements?.find(el => el.isSelected)?.fill || '#000000'
              }}
            />
            {showColorPicker && (
              <div className="color-picker-popover" style={{ position: 'absolute', zIndex: 2000 }}>
                <HexColorPicker
                  color={designState.textElements?.find(el => el.isSelected)?.fill || '#000000'}
                  onChange={handleColorChange}
                />
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Upload Tool */}
      <section className="tool-section">
        <h3>Upload Artwork</h3>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          style={{ display: 'none' }}
        />
        <button 
          className="tool-button"
          onClick={handleUploadClick}
        >
          Upload Image
        </button>
      </section>

      <button 
        className="next-button"
        onClick={onNext}
      >
        Select Sizes and Due Date →
      </button>
    </div>
  );
};

export default DesignTools; 
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import '../styling/Home.css';
import { getRandomArticle } from '../data/localNewsArticles';
import garmentWall from '../assets/images/garment-wall.jpg';
import coffeeTote from '../assets/images/coffeetote.png';

const Home = () => {
  const [randomArticle, setRandomArticle] = useState(null);
  
  // Get current month and year
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    setRandomArticle(getRandomArticle());
  }, []);

  return (
    <>
      <Helmet>
        <title>Custom T-Shirts Des Moines | Screen Printing | Eight Seven Central</title>
        <meta 
          name="description" 
          content="Des Moines' trusted source for custom t-shirts and screen printing since 2010. Premium quality, fast turnaround, and local pickup available. Get your quote today!"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Eight Seven Central",
            "image": [
              "https://eightsevencentral.com/static/media/garment-wall.jpg",
              "https://eightsevencentral.com/static/media/coffeetote.png"
            ],
            "@id": "https://eightsevencentral.com",
            "url": "https://eightsevencentral.com",
            "telephone": "(515) 288-2066",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "863 42nd Street",
              "addressLocality": "Des Moines",
              "addressRegion": "IA",
              "postalCode": "50312",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 41.5868353,
              "longitude": -93.6249593
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "09:00",
              "closes": "17:00"
            },
            "sameAs": [
              "https://www.facebook.com/eightsevencentral",
              "https://www.instagram.com/eightsevencentral"
            ],
            "priceRange": "$$"
          })}
        </script>
      </Helmet>

      <main className="HomePage">
        <header className="Masthead">
          <h1>Custom T-Shirts Des Moines | Eight Seven Central</h1>
          <p className="Dateline">Est. 2010 - Des Moines' Trusted Source for Quality Custom Printing</p>
        </header>

        <section className="MainStory">
          <h2 className="Headline">MILLIONS OF SHIRTS PRINTED IN DES MOINES</h2>
          <p className="Subheading">Local Print Shop Continues Tradition of Excellence</p>
          <p className="LeadParagraph">
            Since 2010, Eight Seven Central has been Des Moines' go-to destination for 
            custom t-shirts and apparel printing, serving thousands of satisfied customers 
            with millions of meticulously crafted prints.
            <Link to="/custom-tshirts-des-moines" className="ReadMore">Learn more about our services...</Link>
          </p>
        </section>

        <section className="PhotoSection">
          <h2 className="SectionHeader">QUALITY GARMENTS, QUALITY PRINTS</h2>
          <div className="PhotoGrid">
            <figure className="NewsPhoto">
              <img 
                src={garmentWall}
                alt="Premium t-shirt selection at Eight Seven Central Des Moines"
                className="NewsImage"
                loading="lazy"
              />
              <figcaption className="PhotoCaption">
                <span className="CaptionDate">{currentMonth} {currentYear}</span> - 
                Our Des Moines facility stocks premium blank garments for quick local turnaround. 
                From classic cotton to performance blends, we stock the highest quality blanks 
                from trusted manufacturers.
              </figcaption>
            </figure>
            
            <figure className="NewsPhoto">
              <img 
                src={coffeeTote}
                alt="Custom printed merchandise at Eight Seven Central Des Moines"
                className="NewsImage"
              />
              <figcaption className="PhotoCaption">
                <span className="CaptionDate">{currentMonth} {currentYear}</span> - 
                Our state-of-the-art Des Moines printing facility ensures your custom t-shirts 
                and apparel are printed with precision and care.
              </figcaption>
            </figure>
          </div>
        </section>

        <div className="NewsGrid">
          <article className="NewsItem">
            {randomArticle && (
              <>
                <h3>{randomArticle.headline}</h3>
                <p>{randomArticle.excerpt}</p>
                <Link to={`/local-news#${randomArticle.id}`} className="ReadMore">Full Story →</Link>
              </>
            )}
          </article>

          <article className="NewsItem">
            <h3>CUSTOM T-SHIRT PRINTING OPTIONS</h3>
            <p>From screen printing to digital printing, we offer complete custom t-shirt solutions in Des Moines.</p>
            <Link to="/digital-printing" className="ReadMore">Full Story →</Link>
          </article>

          <article className="NewsItem">
            <h3>DESIGN SERVICES WIN AWARDS</h3>
            <p>Our Des Moines design team continues to create innovative custom shirt designs for local businesses.</p>
            <Link to="/design" className="ReadMore">Full Story →</Link>
          </article>
        </div>

        <section className="PricingSection">
          <h2 className="SectionHeader">DES MOINES T-SHIRT PRINTING RATES</h2>
          <div itemScope itemType="https://schema.org/PriceSpecification">
            <p className="Subtitle">Current Screen Printing Prices - Bella + Canvas 3001</p>
            <table className="PriceTable">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>1 Color / 1 Side</th>
                  <th>2 Color / 1 Side</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>12</td>
                  <td>$16.25</td>
                  <td>$16.75</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>$14.25</td>
                  <td>$14.75</td>
                </tr>
                <tr>
                  <td>50</td>
                  <td>$12.25</td>
                  <td>$12.75</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Link 
            to="/high-volume-pricing" 
            className="ReadMore"
            aria-label="View complete pricing for custom t-shirts"
          >
            View Full Price List →
          </Link>
        </section>

        <section className="ProcessSection">
          <h2 className="SectionHeader">THE PRINTING PROCESS</h2>
          <div className="ProcessSteps" role="list">
            {['Select Shirts', 'Upload Design', 'Choose Sizes', 'Quick Production', 'Local Pickup'].map((step, index) => (
              <div key={step} className="Step" role="listitem">
                <span className="StepNumber" aria-label={`Step ${index + 1}`}>{index + 1}</span>
                <h3>{step}</h3>
              </div>
            ))}
          </div>
          <Link 
            to="/quote-generator" 
            className="CTA"
            aria-label="Start your custom t-shirt order"
          >
            Begin Your Custom T-Shirt Order Today →
          </Link>
        </section>
      </main>
    </>
  );
};

export default Home; 
import React, { useState, useMemo } from 'react';
import { calculateShirtDesignerPricing } from '../../utils/priceCalculator';
import './OrderDetails.css';
import { useNavigate } from 'react-router-dom';
import { calculateDefaultDate, calculateRushStatus } from '../../utils/dateUtils';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../utils/firebase';

const OrderDetails = ({ designState, setDesignState, onBack, fabricCanvas }) => {
  const totalQuantity = Object.values(designState.quantities).reduce((sum, qty) => sum + qty, 0);
  const navigate = useNavigate();
  
  // Calculate pricing
  const pricing = calculateShirtDesignerPricing(
    designState.quantities,
    designState.isRushOrder,
    designState.deliveryMethod
  );

  // Add a memoized date class calculator
  const dateClassMemo = useMemo(() => {
    const cache = new Map();
    
    return (date) => {
      const dateStr = date.toISOString().split('T')[0];
      
      if (cache.has(dateStr)) {
        return cache.get(dateStr);
      }
      
      const businessDays = calculateRushStatus(dateStr).days;
      let className;
      
      if (businessDays <= 3) {
        className = 'unavailable-date';
      } else if (businessDays <= 7) {
        className = 'rush-date';
      } else {
        className = 'standard-date';
      }
      
      cache.set(dateStr, className);
      return className;
    };
  }, []);

  // Replace the old getDateClass function with the memoized version
  const getDateClass = (date) => dateClassMemo(date);

  const handleDateChange = (date) => {
    const rushStatus = calculateRushStatus(date);
    setDesignState(prev => ({
      ...prev,
      deliveryDate: date,
      isRushOrder: rushStatus.type === 'rush'
    }));
  };

  const handleProceedToCheckout = async () => {
    try {
      if (!fabricCanvas) {
        throw new Error('Canvas not initialized');
      }

      const orderId = `order_${Date.now()}`;

      // Get the mockup URL
      const mockupRef = ref(storage, `products/shirts/00606/${designState.selectedColor.name}-front.jpg`);
      const mockupUrl = await getDownloadURL(mockupRef);

      // Create and upload the preview image
      const previewImage = await new Promise((resolve) => {
        fabricCanvas.clone((clonedCanvas) => {
          clonedCanvas.getObjects().forEach(obj => {
            if (obj.name === 'printArea') {
              clonedCanvas.remove(obj);
            }
          });
          clonedCanvas.renderAll();
          resolve(clonedCanvas.toDataURL({
            format: 'png',
            quality: 1,
            multiplier: 2
          }));
        });
      });

      // Convert data URL to blob and upload to Firebase
      const previewBlob = await (await fetch(previewImage)).blob();
      const previewRef = ref(storage, `artwork/${orderId}/preview.png`);
      await uploadBytes(previewRef, previewBlob);
      const previewUrl = await getDownloadURL(previewRef);

      // Save canvas state
      const canvasState = fabricCanvas.toJSON(['id', 'name', 'type', 'src']);

      // Format artwork data to match ArtworkUpload.js structure
      const artworkData = {
        type: 'complete',
        uploads: {
          frontLarge: {
            previewUrl: previewUrl,
            storagePath: `artwork/${orderId}/preview.png`,
            name: '',
            type: '',
            size: 0
          }
        },
        locations: ['frontLarge']
      };

      sessionStorage.setItem('shirtDesignerState', JSON.stringify({
        orderId,
        material: {
          name: "Bella+Canvas 3001",
          id: "00606",
          selectedColor: designState.selectedColor
        },
        quantities: designState.quantities,
        printLocations: {
          frontLarge: true,
          frontSmall: false,
          backLarge: false,
          backSmall: false,
          leftSleeve: false,
          rightSleeve: false
        },
        deliveryMethod: designState.deliveryMethod,
        deliveryDate: designState.deliveryDate,
        isRushOrder: designState.isRushOrder,
        pricing: {
          ...pricing,
          rushFee: designState.isRushOrder ? 30 : 0
        },
        artworkData: artworkData,
        mockupUrl: mockupUrl,
        canvasState: canvasState
      }));

      console.log('Saved state:', JSON.parse(sessionStorage.getItem('shirtDesignerState')));
      navigate('/checkout-with-stripe');
    } catch (error) {
      console.error('Error preparing checkout:', error);
      alert('There was an error preparing your order. Please try again or contact support if the problem persists.');
    }
  };

  return (
    <div className="order-details">
      <h2>Order Details</h2>

      {/* Size Selection */}
      <section className="details-section">
        <h3>Select Quantities</h3>
        <div className="size-grid">
          {Object.entries(designState.quantities).map(([size, qty]) => (
            <div key={size} className="size-input-group">
              <label htmlFor={`size-${size}`}>{size}</label>
              <input
                id={`size-${size}`}
                type="number"
                min="0"
                placeholder="0"
                value={qty || ''}
                onChange={(e) => setDesignState(prev => ({
                  ...prev,
                  quantities: {
                    ...prev.quantities,
                    [size]: parseInt(e.target.value) || 0
                  }
                }))}
              />
            </div>
          ))}
        </div>
        <div className="total-quantity">
          Total Quantity: {totalQuantity}
        </div>
      </section>

      {/* Delivery Method */}
      <section className="details-section">
        <h3>Delivery Method</h3>
        <div className="delivery-selection">
          <select
            value={designState.deliveryMethod}
            onChange={(e) => setDesignState(prev => ({
              ...prev,
              deliveryMethod: e.target.value
            }))}
          >
            <option value="pickup">Local Pickup (Free)</option>
            <option value="shipping">Shipping (Based on Quantity)</option>
          </select>
        </div>
      </section>

      {/* Add Due Date Section before Pricing Breakdown */}
      <section className="details-section">
        <h3>Select Due Date</h3>
        <div className="date-selection">
          <DatePicker
            selected={designState.deliveryDate ? new Date(designState.deliveryDate) : null}
            onChange={(date) => handleDateChange(date.toISOString().split('T')[0])}
            minDate={new Date()}
            dayClassName={getDateClass}
            className="date-input"
            placeholderText="Select a date"
            calendarClassName="custom-calendar"
          />
          <div className="date-legend">
            <div className="legend-item">
              <span className="color-dot red"></span>
              <span>Not available online</span>
            </div>
            <div className="legend-item">
              <span className="color-dot yellow"></span>
              <span>Rush fee ($30)</span>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Breakdown */}
      {totalQuantity > 0 && (
        <section className="details-section pricing-breakdown">
          <h3>Pricing</h3>
          <div className="price-details">
            <div className="price-row">
              <span>Per shirt:</span>
              <span>${pricing.perItem.toFixed(2)}</span>
            </div>
            <div className="price-row">
              <span>Subtotal:</span>
              <span>${pricing.subtotal.toFixed(2)}</span>
            </div>
            {designState.isRushOrder && (
              <div className="price-row rush-fee">
                <span>Rush Fee:</span>
                <span>${(30).toFixed(2)}</span>
              </div>
            )}
            {pricing.shippingCost > 0 && (
              <div className="price-row">
                <span>Shipping:</span>
                <span>${pricing.shippingCost.toFixed(2)}</span>
              </div>
            )}
            <div className="price-row">
              <span>Tax (7%):</span>
              <span>${pricing.salesTax.toFixed(2)}</span>
            </div>
            <div className="price-row total">
              <span>Total:</span>
              <span>${pricing.total.toFixed(2)}</span>
            </div>
          </div>
        </section>
      )}

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button className="back-button" onClick={onBack}>
          ← Back
        </button>
        <button className="next-button" onClick={handleProceedToCheckout}>
          Checkout →
        </button>
      </div>
    </div>
  );
};

export default OrderDetails; 
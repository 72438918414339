import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { 
  createPaymentIntent, 
  saveOrder, 
  sendOrderConfirmationEmail, 
  notifyAdminOfOrder,
  sendAdminOrderNotification
} from '../services/paymentService';
import '../styling/Checkout.css';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../utils/firebase';

const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  
  const [orderType, setOrderType] = useState(null); // 'quote' or 'shirtDesigner'
  const [orderDetails, setOrderDetails] = useState(null);
  const [contactInfo, setContactInfo] = useState({
    fullName: '',
    email: '',
    phone: '',
    orderNotes: ''
  });
  const [nameOnCard, setNameOnCard] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false);
  const [refreshedArtwork, setRefreshedArtwork] = useState({});
  const [isContactInfoComplete, setIsContactInfoComplete] = useState(false);

  // Add this helper function near the top of the component
  const calculateTotalQuantity = (quantities) => {
    if (!quantities) return 0;
    return Object.values(quantities).reduce((sum, qty) => sum + (parseInt(qty) || 0), 0);
  };

  // Add this function to calculate the grand total
  const calculateGrandTotal = () => {
    if (!orderDetails?.pricing) return '0.00';
    
    const {
      subtotal = 0,
      rushFee = 0,
      shippingCost = 0
    } = orderDetails.pricing;

    // Only apply early purchase discount for quote orders
    const afterEarlyDiscount = orderType === 'quote' ? subtotal * 0.9 : subtotal;
    
    // Then apply the additional discount if it exists
    const afterAllDiscounts = appliedDiscount 
      ? afterEarlyDiscount * 0.01 // Remaining 1% after 99% discount
      : afterEarlyDiscount;
    
    // Calculate sales tax on discounted amount
    const salesTax = afterAllDiscounts * 0.07; // Assuming 7% tax rate
    
    // Calculate final total including rush fee
    const total = afterAllDiscounts + Number(rushFee) + Number(shippingCost) + salesTax;
    
    return Math.max(total, 0).toFixed(2);
  };

  useEffect(() => {
    // Check for quote form state
    const quoteState = sessionStorage.getItem('quoteFormState');
    // Check for shirt designer state
    const shirtState = sessionStorage.getItem('shirtDesignerState');

    if (quoteState) {
      setOrderType('quote');
      setOrderDetails(JSON.parse(quoteState));
    } else if (shirtState) {
      setOrderType('shirtDesigner');
      setOrderDetails(JSON.parse(shirtState));
    } else {
      // No valid state found, redirect to home
      navigate('/');
    }
  }, [navigate]);

  // Add this effect to check when contact info is complete
  useEffect(() => {
    const isComplete = 
      contactInfo.fullName.trim().length > 0 &&
      contactInfo.email.trim().length > 0 &&
      contactInfo.phone.trim().length >= 10; // Assuming US phone numbers
      
    setIsContactInfoComplete(isComplete);
  }, [contactInfo.fullName, contactInfo.email, contactInfo.phone]);

  // Modify the payment intent useEffect
  useEffect(() => {
    let isMounted = true;
    
    const fetchPaymentIntent = async () => {
      if (!stripe || clientSecret || !isContactInfoComplete) return;
      
      const total = parseFloat(calculateGrandTotal());
      if (!total) return;
      
      try {
        // Convert dollars to cents for Stripe
        const amountInCents = Math.round(total * 100);
        
        console.log(`Creating payment intent for $${amountInCents/100}`);
        
        // Include relevant order metadata
        const metadata = {
          customerName: contactInfo.fullName,
          email: contactInfo.email,
          phone: contactInfo.phone,
          itemDescription: `Screen printing order: ${orderDetails.material?.name || 'Custom apparel'} x ${orderDetails.totalQuantity || 0}`,
          orderType: 'screen_printing'
        };

        const response = await fetch('https://us-central1-eightseven-website.cloudfunctions.net/createPaymentIntent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: amountInCents,
            metadata: metadata
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        
        if (!isMounted) return;
        
        if (result.clientSecret) {
          console.log('Successfully retrieved client secret');
          setClientSecret(result.clientSecret);
        } else if (result.error) {
          console.error('Error in payment intent result:', result.error);
          setPaymentError(result.error);
        }
      } catch (error) {
        if (!isMounted) return;
        console.error('Error creating payment intent:', error);
        setPaymentError('Unable to initialize payment system. Please try again later.');
      }
    };

    fetchPaymentIntent();
    
    return () => {
      isMounted = false;
    };
  }, [stripe, isContactInfoComplete, clientSecret]); // Remove individual contact fields from dependencies

  // Add this effect to refresh artwork URLs
  useEffect(() => {
    if (!orderDetails?.artworkData?.uploads) return;

    const refreshArtworkUrls = async () => {
      const refreshed = {};
      
      for (const [location, data] of Object.entries(orderDetails.artworkData.uploads)) {
        if (data.storagePath) {
          try {
            const storageRef = ref(storage, data.storagePath);
            const freshUrl = await getDownloadURL(storageRef);
            refreshed[location] = {
              ...data,
              previewUrl: freshUrl
            };
          } catch (error) {
            console.error('Error refreshing artwork URL:', error);
          }
        }
      }
      
      setRefreshedArtwork(refreshed);
    };

    refreshArtworkUrls();
  }, [orderDetails?.artworkData]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo(prev => ({ ...prev, [name]: value }));
  };

  // Handle payment submission
  const handleSubmitPayment = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements || !clientSecret) {
      setPaymentError('Payment system not fully loaded. Please wait or refresh the page.');
      return;
    }
    
    setIsProcessing(true);
    setPaymentError(null);
    
    try {
      // Get the card element
      const cardElement = elements.getElement(CardElement);
      
      // Confirm card payment
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: nameOnCard,
            email: contactInfo.email,
            phone: contactInfo.phone
          }
        }
      });
      
      if (error) {
        setPaymentError(error.message);
        setIsProcessing(false);
        return;
      }
      
      if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'requires_capture') {
        try {
          // Generate order number
          const orderNumber = `ESC-${Date.now()}`;
          
          // Create complete order data
          const completeOrderData = {
            contactInfo: {
              fullName: contactInfo.fullName,
              email: contactInfo.email,
              phone: contactInfo.phone,
              orderNotes: contactInfo.orderNotes
            },
            orderDetails: {
              ...orderDetails,
              material: orderDetails.material,
              selectedColor: orderDetails.material?.selectedColor,
              quantities: orderDetails.quantities,
              printLocations: orderDetails.printLocations,
              designs: orderDetails.designs,
              deliveryMethod: orderDetails.deliveryMethod,
              deliveryAddress: orderDetails.deliveryAddress,
              deliveryDate: orderDetails.deliveryDate,
              pricing: {
                perItem: orderDetails.pricing?.perItem || 0,
                subtotal: orderDetails.pricing?.subtotal || 0,
                discount: orderDetails.pricing?.subtotal * 0.1 || 0,
                rushFee: orderDetails.pricing?.rushFee || 0,
                shippingCost: orderDetails.pricing?.shippingCost || 0,
                salesTax: orderDetails.pricing?.salesTax || 0,
                total: parseFloat(calculateGrandTotal())
              }
            },
            orderNumber: orderNumber,
            paymentIntent: {
              id: paymentIntent.id,
              amount: paymentIntent.amount,
              status: paymentIntent.status
            }
          };

          console.log('Submitting complete order data:', completeOrderData);

          // Save the order to your database
          await saveOrder(paymentIntent.id, completeOrderData.orderDetails, completeOrderData.contactInfo);
          
          // Send confirmation emails with complete order data
          await sendOrderConfirmationEmail(completeOrderData);
          
          // Notify admin with complete order data
          await notifyAdminOfOrder(completeOrderData);
          
          // Clear the session storage after successful order
          sessionStorage.removeItem('quoteFormState');

          // Navigate to confirmation
          navigate('/order-confirmation', {
            state: {
              name: contactInfo.fullName,
              email: contactInfo.email,
              orderNumber: orderNumber
            }
          });
        } catch (error) {
          console.error('Order processing error:', {
            message: error.message,
            code: error.code,
            details: error.details,
            stack: error.stack
          });
          setPaymentError('Error processing order. Payment was successful, but there was an issue saving your order. Our team will contact you shortly.');
        }
      } else {
        setPaymentError('Payment processing error. Please try again.');
      }
    } catch (error) {
      console.error('Payment error:', error);
      setPaymentError('Error processing payment. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Handle going back to the order form
  const handleBack = () => {
    navigate(-1);
  };

  // Check if we have any designs with preview URLs
  const hasArtworkPreviews = () => {
    if (!orderDetails?.artworkData?.uploads) return false;
    return Object.values(refreshedArtwork).some(design => design && design.previewUrl);
  };

  // Stripe Card Element options for styling
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#333',
        fontFamily: 'Courier New, Courier, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  // Add this function to handle discount code application
  const handleApplyDiscountCode = () => {
    setIsApplyingDiscount(true);
    
    setTimeout(() => {
      if (discountCode.toLowerCase() === '99off') {  // Changed back to '99off'
        // Calculate the subtotal after early purchase discount
        const afterEarlyDiscount = orderDetails.pricing.subtotal * 0.9; // After 10% early discount
        const discountAmount = afterEarlyDiscount * 0.99; // Apply 99% to remaining amount
        
        setAppliedDiscount({
          code: discountCode,
          amount: discountAmount,
          percentage: 99
        });
        
        // Update order details with discount
        const updatedOrderDetails = {
          ...orderDetails,
          pricing: {
            ...orderDetails.pricing,
            discount: discountAmount
          }
        };
        
        setOrderDetails(updatedOrderDetails);
        setDiscountCode('');
      } else {
        setPaymentError('Invalid discount code. Please try again.');
        setTimeout(() => setPaymentError(null), 3000);
      }
      setIsApplyingDiscount(false);
    }, 500);
  };

  // Add this function to remove applied discount
  const handleRemoveDiscount = () => {
    const updatedOrderDetails = {
      ...orderDetails,
      pricing: {
        ...orderDetails.pricing,
        discount: 0,
        total: orderDetails.pricing.subtotal + (appliedDiscount?.amount || 0)
      }
    };
    
    setOrderDetails(updatedOrderDetails);
    setAppliedDiscount(null);
  };

  // Add this function to handle payment intent updates
  const updatePaymentIntent = async (newAmount) => {
    if (!clientSecret) return;
    
    try {
      const amountInCents = Math.round(newAmount * 100);
      const response = await fetch('https://us-central1-eightseven-website.cloudfunctions.net/updatePaymentIntent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientSecret,
          amount: amountInCents
        })
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      if (result.clientSecret) {
        setClientSecret(result.clientSecret);
      }
    } catch (error) {
      console.error('Error updating payment intent:', error);
      setPaymentError('Error updating payment amount. Please refresh the page and try again.');
    }
  };

  // Render different order summaries based on type
  const renderOrderSummary = () => {
    if (orderType === 'quote') {
      return (
        <div className="order-summary">
          <h2>Quote Order Summary</h2>
          {/* Quote-specific summary */}
        </div>
      );
    } else if (orderType === 'shirtDesigner') {
      return (
        <div className="order-summary">
          <h2>Custom Shirt Order Summary</h2>
          {/* Shirt designer-specific summary */}
        </div>
      );
    }
  };

  // If no order details, show loading or error
  if (!orderDetails) {
    return <div className="checkout-container">Loading order details...</div>;
  }

  // Calculate discounted amounts for display
  const subtotal = orderDetails.pricing?.subtotal || 0;
  const afterEarlyDiscount = subtotal * 0.9; // 10% off
  const afterAllDiscounts = appliedDiscount 
    ? afterEarlyDiscount * 0.01 // Remaining 1% after 99% discount
    : afterEarlyDiscount;
  const salesTax = afterAllDiscounts * 0.07; // Calculate tax on discounted amount

  return (
    <div className="checkout-page">
      {renderOrderSummary()}
      <div className="checkout-container">
        {/* Order Summary Section */}
        <div className="order-summary-section">
          <div className="order-summary-grid">
            {/* Column 1: Artwork Preview */}
            <div className="order-summary-column artwork-column">
              <h3>Artwork Preview</h3>
              <div className="artwork-container">
                {hasArtworkPreviews() ? (
                  Object.entries(refreshedArtwork).map(([location, design]) => (
                    design && design.previewUrl && (
                      <div key={location} className="artwork-preview-box">
                        <p>{location.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</p>
                        <img 
                          src={design.previewUrl} 
                          alt={`Design for ${location}`}
                          onError={(e) => {
                            console.error('Error loading image:', e);
                            e.target.style.display = 'none';
                          }}
                        />
                        <div className="file-info">
                          <span className="file-name">{design.name}</span>
                          <span className="file-size">({(design.size / 1024 / 1024).toFixed(2)} MB)</span>
                        </div>
                      </div>
                    )
                  ))
                ) : (
                  <div className="no-artwork">
                    <p>No artwork preview available</p>
                  </div>
                )}
              </div>
            </div>
            
            {/* Column 2: Order Details */}
            <div className="order-summary-column details-column">
              <h3>Order Details</h3>
              <div className="details-list">
                <div className="detail-item">
                  <span className="detail-label">Garment:</span>
                  <span className="detail-value">{orderDetails.material?.name || 'Not specified'}</span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Color:</span>
                  <span className="detail-value">{orderDetails.material?.selectedColor?.name || 'Not specified'}</span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Print Locations:</span>
                  <span className="detail-value">
                    {Object.entries(orderDetails.printLocations || {})
                      .filter(([_, isSelected]) => isSelected)
                      .map(([location]) => location.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()))
                      .join(', ') || 'None'}
                  </span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Delivery Method:</span>
                  <span className="detail-value">
                    {orderDetails.deliveryMethod === 'pickup' ? 'Store Pickup' :
                     orderDetails.deliveryMethod === 'local' ? 'Local Delivery' :
                     orderDetails.deliveryMethod === 'shipping' ? 'Shipping' : 
                     'Not specified'}
                  </span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Due Date:</span>
                  <span className="detail-value">{orderDetails.deliveryDate || 'Not specified'}</span>
                </div>
              </div>
            </div>
            
            {/* Column 3: Size Breakdown */}
            <div className="order-summary-column sizes-column">
              <h3>Size Breakdown</h3>
              <div className="size-table-container">
                <table className="size-table">
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(orderDetails.quantities || {})
                      .filter(([_, qty]) => qty > 0)
                      .map(([size, quantity]) => (
                        <tr key={size}>
                          <td>{size}</td>
                          <td>{quantity}</td>
                        </tr>
                      ))}
                    {Object.values(orderDetails.quantities || {}).every(qty => qty === 0) && (
                      <tr>
                        <td colSpan="2">No sizes selected</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td><strong>Total</strong></td>
                      <td><strong>{calculateTotalQuantity(orderDetails.quantities)}</strong></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          
          {/* Full width pricing row */}
          <div className="pricing-summary">
            <table className="pricing-table">
              <tbody>
                <tr>
                  <td>Subtotal ({calculateTotalQuantity(orderDetails.quantities)} shirts @ ${orderDetails.pricing?.perItem?.toFixed(2)})</td>
                  <td>${subtotal.toFixed(2)}</td>
                </tr>
                {orderType === 'quote' && (  // Only show for quote orders
                  <tr>
                    <td>10% Early Purchase Discount</td>
                    <td>-${(subtotal * 0.1).toFixed(2)}</td>
                  </tr>
                )}
                {appliedDiscount && (
                  <tr className="discount-amount-row">
                    <td>Testing Discount (99% off remaining balance)</td>
                    <td>-${(afterEarlyDiscount * 0.99).toFixed(2)}</td>
                  </tr>
                )}
                <tr>
                  <td>
                    Rush Fee
                    {orderType === 'shirtDesigner' && orderDetails.isRushOrder && (
                      <span className="rush-fee-note"> (Rush delivery selected)</span>
                    )}
                  </td>
                  <td>${orderDetails.pricing?.rushFee?.toFixed(2) || '0.00'}</td>
                </tr>
                <tr>
                  <td>Shipping</td>
                  <td>${orderDetails.pricing?.shippingCost?.toFixed(2) || '0.00'}</td>
                </tr>
                <tr>
                  <td>Sales Tax (on discounted amount)</td>
                  <td>${salesTax.toFixed(2)}</td>
                </tr>
                <tr className="pricing-total">
                  <td><strong>Grand Total</strong></td>
                  <td><strong>${calculateGrandTotal()}</strong></td>
                </tr>
              </tbody>
            </table>
            
            {/* Add the discount code section here */}
            <div className="discount-code-row">
              <div className="discount-code-container">
                {!appliedDiscount ? (
                  <div className="discount-input-container">
                    <input
                      type="text"
                      className="discount-input"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                      placeholder="Enter discount code"
                      disabled={isApplyingDiscount}
                    />
                    <button
                      className="apply-discount-button"
                      onClick={handleApplyDiscountCode}
                      disabled={!discountCode || isApplyingDiscount}
                    >
                      {isApplyingDiscount ? 'Applying...' : 'Apply'}
                    </button>
                  </div>
                ) : (
                  <div className="applied-discount">
                    <span className="discount-label">
                      Discount applied: {appliedDiscount.percentage}% off
                    </span>
                    <button 
                      className="remove-discount-button"
                      onClick={handleRemoveDiscount}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        
        {/* What Happens Next Section */}
        <div className="what-happens-next">
          <h2>What Happens Next?</h2>
          <p>At Eight Seven Central, we believe in providing personalized attention to every order. Here's what you can expect:</p>
          <ul>
            <li>A temporary authorization hold will be placed on your card for the estimated amount, but you won't be charged until your order is reviewed.</li>
            <li>One of our experienced Project Managers will review your order within 1 business day to ensure you're getting the best possible pricing and product recommendations.</li>
            <li>You'll receive a digital mock-up of your design by email, showing exactly how your artwork will look on your chosen garments.</li>
            <li>Production begins only after you've approved the mock-up and final pricing.</li>
          </ul>
          <p>While the final price typically matches the estimate, occasionally we may suggest minor adjustments to ensure you get the best possible outcome for your order. We're committed to transparency and will always communicate any changes before proceeding.</p>
        </div>
        
        {/* Contact & Payment Information Form */}
        <div className="payment-form-section">
          <h2>Contact & Payment Information</h2>
          <form onSubmit={handleSubmitPayment}>
            {/* Row 1: Name and Email */}
            <div className="form-row two-columns">
              <div className="form-group">
                <label htmlFor="fullName">Full Name *</label>
                <input 
                  type="text" 
                  id="fullName" 
                  value={contactInfo.fullName}
                  onChange={(e) => setContactInfo({...contactInfo, fullName: e.target.value})}
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="email">Email *</label>
                <input 
                  type="email" 
                  id="email" 
                  value={contactInfo.email}
                  onChange={(e) => setContactInfo({...contactInfo, email: e.target.value})}
                  required
                />
              </div>
            </div>
            
            {/* Row 2: Phone and Credit Card */}
            <div className="form-row two-columns">
              <div className="form-group">
                <label htmlFor="phone">Phone *</label>
                <input 
                  type="tel" 
                  id="phone" 
                  value={contactInfo.phone}
                  onChange={(e) => setContactInfo({...contactInfo, phone: e.target.value})}
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="nameOnCard">Name on Card *</label>
                <input 
                  type="text" 
                  id="nameOnCard" 
                  value={nameOnCard}
                  onChange={(e) => setNameOnCard(e.target.value)}
                  required
                />
              </div>
            </div>
            
            {/* Unified Credit Card Element */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="card-element">Credit Card Information *</label>
                <div className="card-element-container">
                  <CardElement id="card-element" options={cardElementOptions} />
                </div>
                {paymentError && <div className="card-error">{paymentError}</div>}
              </div>
            </div>
            
            {/* Order Notes */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="orderNotes">Order Notes (Optional)</label>
                <textarea 
                  id="orderNotes" 
                  value={contactInfo.orderNotes}
                  onChange={(e) => setContactInfo({...contactInfo, orderNotes: e.target.value})}
                  rows="3"
                />
              </div>
            </div>
            
            {/* Action Buttons */}
            <div className="form-actions">
              <button 
                type="button" 
                className="back-button" 
                onClick={handleBack}
                disabled={isProcessing}
              >
                Back
              </button>
              <button 
                type="submit" 
                className="place-order-button"
                disabled={isProcessing || !stripe}
              >
                {isProcessing ? 'Processing...' : `Place Order ($${calculateGrandTotal()})`}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
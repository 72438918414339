import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Checkout from './Checkout';
import '../styling/CheckoutWithStripe.css';

// Initialize Stripe with your live publishable key
const stripePromise = loadStripe('pk_live_51QxWPtAr2ReprhwMv8MUsMJS9vWEVKvladXHk723rWAMuBazf0v6AoZnWbHyEI1H0ex7PDYALLsG5XZA3OWGLZTA003k0tJiNt');

const CheckoutWithStripe = () => {
  // Options for Stripe Elements
  const options = {
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#2b2b2b',
        fontFamily: 'Courier New, Courier, monospace',
      },
    },
  };

  return (
    <div className="checkout-with-stripe-container">
      <Elements stripe={stripePromise} options={options}>
        <Checkout />
      </Elements>
    </div>
  );
};

export default CheckoutWithStripe; 
import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { storage } from '../../utils/firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import './DesignCanvas.css';

const DesignCanvas = ({ designState, setDesignState, onCanvasReady }) => {
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);
  const currentImageRef = useRef(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
  const [selectedTextObject, setSelectedTextObject] = useState(null);

  // Initial canvas setup
  useEffect(() => {
    // Create canvas with fixed dimensions
    const canvas = new fabric.Canvas(canvasRef.current, {
      width: 450,
      height: 600,
      backgroundColor: 'white'
    });

    fabricCanvasRef.current = canvas;
    
    // Move the styling setup AFTER canvas is assigned to ref
    fabricCanvasRef.current.set({
      selectionColor: 'rgba(139, 168, 136, 0.2)',
      selectionBorderColor: '#8BA888',
      selectionLineWidth: 1
    });

    // Apply object controls after canvas is ready
    const setObjectDefaults = () => {
      fabric.Object.prototype.set({
        borderColor: '#8BA888',
        cornerColor: '#8BA888',
        cornerSize: 10,
        transparentCorners: false,
        padding: 8
      });
    };

    // Wait for next tick to ensure canvas is ready
    setTimeout(setObjectDefaults, 0);
    
    onCanvasReady(canvas);

    // Add keyboard event listener for delete
    const handleKeyDown = (e) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        const activeObject = fabricCanvasRef.current.getActiveObject();
        if (activeObject && activeObject !== currentImageRef.current) {
          // Don't allow deletion of the shirt mockup
          fabricCanvasRef.current.remove(activeObject);
          fabricCanvasRef.current.renderAll();

          // Update state to remove the deleted object
          setDesignState(prev => {
            const newState = { ...prev };
            
            if (activeObject.type === 'text') {
              // Ensure textElements exists before filtering
              if (Array.isArray(newState.textElements)) {
                newState.textElements = newState.textElements.filter(
                  el => el.text !== activeObject.text
                );
              } else {
                newState.textElements = [];
              }
            } else if (activeObject.type === 'image') {
              // Ensure uploadedArtwork exists before filtering
              if (Array.isArray(newState.uploadedArtwork)) {
                newState.uploadedArtwork = newState.uploadedArtwork.filter(
                  art => art.url !== activeObject.getSrc()
                );
              } else {
                newState.uploadedArtwork = [];
              }
            }
            
            return newState;
          });
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      fabricCanvasRef.current?.dispose();
    };
  }, []);

  // Add separate useEffect for resize handling
  useEffect(() => {
    const resizeCanvas = () => {
      const container = document.querySelector('.design-canvas-container');
      if (container && fabricCanvasRef.current) {
        // Calculate scale based on container width
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        
        const scaleX = containerWidth / 450;
        const scaleY = containerHeight / 600;
        const scale = Math.min(scaleX, scaleY);
        
        // Update canvas container scale and position
        const canvasContainer = document.querySelector('.canvas-container');
        if (canvasContainer) {
          canvasContainer.style.transform = `scale(${scale})`;
          
          // Center the canvas in the container
          const leftOffset = (containerWidth - (450 * scale)) / 2;
          const topOffset = (containerHeight - (600 * scale)) / 2;
          
          canvasContainer.style.left = `${leftOffset}px`;
          canvasContainer.style.top = `${topOffset}px`;
        }
        
        fabricCanvasRef.current.renderAll();
      }
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas(); // Initial resize
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  // Update shirt mockup when color changes
  useEffect(() => {
    const loadShirtMockup = async () => {
      if (!fabricCanvasRef.current || !designState.selectedColor) return;

      try {
        const mockupPath = `products/shirts/00606/${designState.selectedColor.name}-front.jpg`;
        const mockupRef = ref(storage, mockupPath);
        const imageUrl = await getDownloadURL(mockupRef);

        // Save all existing objects except the shirt mockup and print area
        const existingObjects = fabricCanvasRef.current.getObjects().filter(obj => 
          obj !== currentImageRef.current && 
          obj.name !== 'printArea' && 
          obj.type !== 'text' && 
          obj.type !== 'i-text' &&
          obj.type !== 'image'
        );

        // Save design elements (text and uploaded images)
        const designElements = fabricCanvasRef.current.getObjects().filter(obj =>
          obj.type === 'i-text' || 
          (obj.type === 'image' && obj !== currentImageRef.current)
        );

        fabric.Image.fromURL(imageUrl, (fabricImage) => {
          // Remove existing mockup
          if (currentImageRef.current) {
            fabricCanvasRef.current.remove(currentImageRef.current);
          }
          
          // Clear print area
          fabricCanvasRef.current.getObjects().forEach(obj => {
            if (obj.name === 'printArea' || obj.type === 'text' && obj.text === 'Print Area') {
              fabricCanvasRef.current.remove(obj);
            }
          });

          // Set up new shirt mockup with proper scaling
          fabricImage.set({
            selectable: false,
            evented: false,
            originX: 'center',
            originY: 'center'
          });

          // Calculate scale to maintain aspect ratio
          const scaleX = (fabricCanvasRef.current.width * 0.9) / fabricImage.width;
          const scaleY = (fabricCanvasRef.current.height * 0.9) / fabricImage.height;
          const scale = Math.min(scaleX, scaleY);
          
          fabricImage.scale(scale);

          // Center the image
          fabricCanvasRef.current.add(fabricImage);
          fabricImage.center();
          currentImageRef.current = fabricImage;
          fabricCanvasRef.current.sendToBack(fabricImage);

          // Add print area
          const printArea = new fabric.Rect({
            width: 172,
            height: 210,
            fill: 'transparent',
            stroke: '#333333',
            strokeWidth: 2,
            strokeDashArray: [5, 5],
            selectable: false,
            evented: false,
            name: 'printArea'
          });

          // Add print area to canvas and center it
          fabricCanvasRef.current.add(printArea);
          fabricCanvasRef.current.centerObject(printArea);

          // Move the print area down by adjusting its top position
          // Changed from -80 to -60 to move it down by 20 pixels
          printArea.set({
            top: printArea.top - 60
          });

          // Restore all design elements
          designElements.forEach(obj => {
            fabricCanvasRef.current.add(obj);
          });

          // Restore any other existing objects
          existingObjects.forEach(obj => {
            fabricCanvasRef.current.add(obj);
          });

          fabricCanvasRef.current.renderAll();
        }, {
          crossOrigin: 'anonymous'
        });

      } catch (error) {
        console.error('Error in loadShirtMockup:', error);
      }
    };

    loadShirtMockup();
  }, [designState.selectedColor]);

  // Add this effect to handle text selection
  useEffect(() => {
    if (!fabricCanvasRef.current) return;

    fabricCanvasRef.current.on('selection:created', (e) => {
      if (e.selected[0].type === 'i-text') {
        setSelectedTextObject(e.selected[0]);
        // Update design state to mark this text as selected
        setDesignState(prev => ({
          ...prev,
          textElements: prev.textElements.map(el => ({
            ...el,
            isSelected: el.text === e.selected[0].text
          }))
        }));
      }
    });

    fabricCanvasRef.current.on('selection:cleared', () => {
      setSelectedTextObject(null);
      // Clear selection in design state
      setDesignState(prev => ({
        ...prev,
        textElements: prev.textElements.map(el => ({
          ...el,
          isSelected: false
        }))
      }));
    });

    return () => {
      if (fabricCanvasRef.current) {
        fabricCanvasRef.current.off('selection:created');
        fabricCanvasRef.current.off('selection:cleared');
      }
    };
  }, []);

  // Update the text handling effect to watch for font and color changes
  useEffect(() => {
    if (!fabricCanvasRef.current || !designState.textElements) return;

    // Update existing text objects when their properties change
    designState.textElements.forEach(textElement => {
      if (textElement.isSelected && selectedTextObject) {
        selectedTextObject.set({
          fontFamily: textElement.fontFamily,
          fill: textElement.fill,
          fontWeight: textElement.bold ? 'bold' : 'normal',
          fontStyle: textElement.italic ? 'italic' : 'normal',
          underline: textElement.underline
        });
        fabricCanvasRef.current.renderAll();
      }
    });

    // Handle new text elements
    const currentTextCount = fabricCanvasRef.current.getObjects().filter(obj => obj.type === 'i-text').length;
    
    if (designState.textElements.length > currentTextCount) {
      const newTextElement = designState.textElements[designState.textElements.length - 1];
      
      const fabricText = new fabric.IText(newTextElement.text, {
        left: fabricCanvasRef.current.width / 2,
        top: (fabricCanvasRef.current.height / 2) - 100,
        fontSize: newTextElement.fontSize,
        fill: newTextElement.fill,
        fontFamily: newTextElement.fontFamily || 'Arial',
        fontWeight: newTextElement.bold ? 'bold' : 'normal',
        fontStyle: newTextElement.italic ? 'italic' : 'normal',
        underline: newTextElement.underline,
        selectable: true,
        editable: true,
        centeredScaling: true,
        originX: 'center',
        originY: 'center'
      });

      // Add to canvas
      fabricCanvasRef.current.add(fabricText);
      fabricCanvasRef.current.setActiveObject(fabricText);
      
      // Select all text when added
      fabricText.selectAll();
      fabricText.enterEditing();
      
      // Center the text
      fabricText.setCoords();
      fabricCanvasRef.current.renderAll();
    }
  }, [designState.textElements]);

  // Add canvas click handler to exit text editing
  useEffect(() => {
    if (!fabricCanvasRef.current) return;

    fabricCanvasRef.current.on('mouse:down', (e) => {
      if (!e.target) {
        const activeObject = fabricCanvasRef.current.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
          activeObject.exitEditing();
        }
      }
    });
  }, []);

  // Add effect to handle uploaded artwork
  useEffect(() => {
    if (!fabricCanvasRef.current) return;

    // Check if there are new uploads to add
    const currentImageCount = fabricCanvasRef.current.getObjects().filter(
      obj => obj.type === 'image' && obj !== currentImageRef.current
    ).length;
    
    if (designState.uploadedArtwork && 
        designState.uploadedArtwork.length > currentImageCount) {
      // Get the newest upload
      const newArtwork = designState.uploadedArtwork[designState.uploadedArtwork.length - 1];
      
      fabric.Image.fromURL(newArtwork.url, (fabricImage) => {
        // Scale image to reasonable size (max 200px in either dimension)
        const scale = Math.min(
          200 / fabricImage.width,
          200 / fabricImage.height,
          1
        );

        fabricImage.set({
          left: fabricCanvasRef.current.width / 2,
          top: (fabricCanvasRef.current.height / 2) - 100,
          scaleX: scale,
          scaleY: scale,
          originX: 'center',
          originY: 'center',
          selectable: true,
          hasControls: true,
          hasBorders: true
        });

        // Add to canvas
        fabricCanvasRef.current.add(fabricImage);
        fabricCanvasRef.current.setActiveObject(fabricImage);
        
        // Center the image
        fabricImage.setCoords();
        fabricCanvasRef.current.renderAll();
        
        // Add event listeners for image modifications
        fabricImage.on('modified', () => {
          // You can add state updates here if needed
        });
      }, {
        crossOrigin: 'anonymous'
      });
    }
  }, [designState.uploadedArtwork]);

  // Add zoom toggle function
  const toggleZoom = () => {
    const canvas = fabricCanvasRef.current;
    if (!canvas) return;

    setIsZoomed(prev => {
      if (!prev) {
        // Zooming in to print area
        const printArea = canvas.getObjects().find(obj => obj.type === 'rect' && obj.name === 'printArea');
        if (printArea) {
          // Reset zoom and viewport first
          canvas.setZoom(1);
          canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
          
          // Calculate zoom to fit print area
          const zoom = Math.min(
            (canvas.width * 0.8) / printArea.width,
            (canvas.height * 0.8) / printArea.height
          );
          
          // Calculate center position based on print area center
          const printAreaCenter = printArea.getCenterPoint();
          const canvasCenter = {
            x: canvas.width / 2,
            y: canvas.height / 2
          };
          
          // Calculate the translation needed to center the print area
          const translateX = canvasCenter.x - (printAreaCenter.x * zoom);
          const translateY = canvasCenter.y - (printAreaCenter.y * zoom) - 40;
          
          // Apply the transformation
          canvas.setViewportTransform([zoom, 0, 0, zoom, translateX, translateY]);
        }
      } else {
        // Zooming out to full view
        canvas.setZoom(1);
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      }
      canvas.requestRenderAll();
      return !prev;
    });
  };

  // Add new effect to load default color on mount
  useEffect(() => {
    // Only load default if no color is selected
    if (!designState.selectedColor) {
      setDesignState(prev => ({
        ...prev,
        selectedColor: {
          name: 'sage',  // Set default color to sage
          displayName: 'Sage'
        }
      }));
    }
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="design-canvas-container">
      <div className="zoom-toggle">
        <button 
          className={`toggle-button ${isZoomed ? 'zoomed' : ''}`}
          onClick={toggleZoom}
        >
          {isZoomed ? 'View Full Shirt' : 'View Print Area'}
        </button>
      </div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default DesignCanvas; 